import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

// Live listener class
import Live from './_live';

class groupSessions extends Live {
  api: API;
  constructor(api: API) {
    super();

    // Setup a reference to the API
    this.api = api;
  }

  mount(): void {
    // Setup listeners
    const role = this.api.credentials?.role;
    const currentUserId = firebase.auth().currentUser?.uid;

    if (role && ['student', 'tutor'].includes(role) && currentUserId) {
      this.listener(
        'groupsessionsMyLiveBookings',
        firebase.firestore().collection('groupsessions')
      );
    }
  }

  async searchGroupSessions(query: any): Promise<any> {
    // Determine the user's local timezone dynamically
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Include the userTimezone in the query payload
    const payload = {
      data: query,
      timezone: userTimezone
    };

    return this.api._http(
      'groupsessions/search',
      'POST',
      { data: payload },
      false
    );
  }

  /*
    API endpoint for making a booking
  */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async bookGroupSessionBooking(booking: any): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    console.log('booking', booking);

    // Make the request and get the response
    return await this.api._http(
      `groupsessions/session/book`,
      'PUT',
      { data: { booking } },
      false
    );
  }

  async getGroupSessionsHistory(
    startDate: number,
    endDate: number
  ): Promise<any> {
    const role = this.api.credentials?.role;
    if (!role || !['student', 'tutor'].includes(role)) {
      throw new Error('Unauthorized access');
    }

    const currentUserId = firebase.auth().currentUser?.uid;
    if (!currentUserId) {
      throw new Error('User not authenticated');
    }

    // Fetch group sessions where the 'when' field is within the date range
    const groupSessionsSnapshot = await firebase
      .firestore()
      .collection('groupsessions')
      .where('when', '>=', startDate)
      .where('when', '<=', endDate)
      .get();

    const groupSessionsHistory: any[] = [];

    groupSessionsSnapshot.forEach((doc) => {
      const groupSession = doc.data();
      const sessionBookings = groupSession.bookings || [];

      // Filter bookings based on their 'when' and 'status'
      const filteredBookings = sessionBookings.filter((booking: any) => {
        const bookingWhen = booking.when;
        const isValidStatus = [
          'tutor-absent',
          'student-absent',
          'pending',
          'completed'
        ].includes(booking.status);
        const isWithinRange =
          bookingWhen >= startDate && bookingWhen <= endDate;
        return isValidStatus && isWithinRange;
      });

      if (filteredBookings.length > 0) {
        groupSessionsHistory.push({
          id: doc.id,
          ...groupSession,
          bookings: filteredBookings
        });
      }
    });

    return groupSessionsHistory;
  }
  async joinGroupSession(id: string, bookingId: string) {
    // Run the HTTP function
    return this.api._http(
      `groupsessions/groupsession/${id}/${bookingId}/join`,
      'PUT',
      { data: {} },
      false
    );
  }
}

export default groupSessions;
