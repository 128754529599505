export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  actionsDivider: {
    marginBottom: theme.spacing(4)
  },
  hideSmall: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  reminderAlert: {
    marginBottom: theme.spacing(1.5)
  },
  dateControls: {
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(4)
  },
  avatar: {
    width: 100,
    height: 100,
    transition: 'opacity 200ms cubic-bezier(0, 1, 1, 1)',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer'
    }
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 0
    }
  }
});
