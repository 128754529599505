import { useState, useCallback, useContext, useEffect } from 'react';
import {
  isSupported,
  LocalTrack,
  createLocalTracks,
  RemoteVideoTrack
} from 'twilio-video';
import { CSSProperties } from 'react';

// Material ui components
import { Typography, Button, Divider } from '@material-ui/core';

// Mateirla UI Lab
import { Alert } from '@material-ui/lab';

// Authentication
import { CallContext, CallStatus } from 'services/call';

import useWindowSize from 'hooks/useWindowSize';

// Location hook
import { useLocation } from 'react-router-dom';

// So we can parse the success messages
import queryString from 'query-string';

// Component imports
import {
  Participant,
  Controls,
  LocalPreview,
  MaterialsPanel,
  Countdown,
  ScreenShare
} from './components';

// Booking info component
import { BookingInfo, Portlet } from 'components';

import { Status } from 'views/shared';

// Component styles
import useStyles from './styles';
import { AuthContext } from 'services/auth';
import { VirtualBackgroundProcessor } from '@twilio/video-processors';

function getMediaErrorMessage(error: Error) {
  switch (error.name) {
    default:
      return 'An unexpected error occured';
    case 'NotFoundError':
      return `Please ensure you have at least one input device connected, and that its enabled in your browser's settings. `;
    case 'NotAllowedError':
      return `Please provide e-English with access to your camera/microphone in your browser's settings.`;
    case 'NotReadableError':
      return `Please close all other apps and tabs using`;
  }
}

function Call(): JSX.Element {
  // Get the token from the state
  const { token, room } = queryString.parse(useLocation().search);
  const call = useContext(CallContext);
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [previewTracks, setPreviewTracks] = useState<LocalTrack[] | undefined>(
    undefined
  );
  const [previewError, setPreviewError] = useState<Error | null>(null);
  const [resource, setResource] = useState<string | null>(null);

  const [remoteScreenShareTrack, setRemoteScreenShareTrack] =
    useState<RemoteVideoTrack | null>(null);

  const [virtualBackgroundProcessor, setVirtualBackgroundProcessor] =
    useState<VirtualBackgroundProcessor | null>(null);
  const [virtualBackgroundApplied, setVirtualBackgroundApplied] =
    useState<boolean>(false); // State to manage virtual background effect application

  const [width] = useWindowSize();

  const [, bookingId] = (room as string).split('-');

  useEffect(() => {
    const findRemoteScreenShareTrack = () => {
      const track = call.participants
        .flatMap((participant) => Array.from(participant.videoTracks.values()))
        .find(
          (trackPublication) => trackPublication.track?.name === 'screen-share'
        )?.track as RemoteVideoTrack;

      setRemoteScreenShareTrack(track || null);
    };

    findRemoteScreenShareTrack();

    call.participants.forEach((participant) => {
      participant.on('trackSubscribed', findRemoteScreenShareTrack);
      participant.on('trackUnsubscribed', findRemoteScreenShareTrack);
    });

    return () => {
      call.participants.forEach((participant) => {
        participant.off('trackSubscribed', findRemoteScreenShareTrack);
        participant.off('trackUnsubscribed', findRemoteScreenShareTrack);
      });
    };
  }, [call.localParticipant, call.participants, call.screenSharing]);

  const leaveCall = useCallback(() => {
    if (call.leave && call.connected) {
      call.leave();
      if (bookingId) localStorage.removeItem(`call-${bookingId}`);
    }
  }, [bookingId, call]);

  // Local preview & cleanup hook
  useEffect(() => {
    async function setupLocalPreview() {
      try {
        const tracks = await createLocalTracks();
        setPreviewTracks(tracks);
      } catch (error) {
        console.error('Could not create local tracks!', error);
        setPreviewError(error as Error);
      }
    }

    window.addEventListener('beforeunload', leaveCall);
    window.addEventListener('pagehide', leaveCall);

    setupLocalPreview();
    return () => {
      if (call.leave) call.leave();
      if (!call.error) {
        window.close();
        if (bookingId) localStorage.removeItem(`call-${bookingId}`);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinRoom = useCallback(() => {
    if (isSupported && call.join && token && room) {
      if (!call.connected)
        call.join(token as string, room as string, previewTracks);

      // Trigger a message for joiner
      auth?.api.chat.send(
        bookingId,
        `${auth?.user?.displayName} Joined the call`
      );
    }

    return () => {
      if (call.leave) call.leave();
      if (!call.error) {
        window.close();
        if (bookingId) localStorage.removeItem(`call-${bookingId}`);
      }
    };
  }, [call, token, room, previewTracks, bookingId, auth]);

  if (!isSupported) {
    return (
      <Status
        title="Browser does not support video calling"
        message="We require a recent version of Chrome and Firefox, or Safari 11 and above."
      />
    );
  } else if (call.error) {
    return <Status title="Video Calling Error" message={call.error.message} />;
  } else if (previewError) {
    return (
      <Status
        title="Camera / Mic Error"
        message={getMediaErrorMessage(previewError)}
      />
    );
  } else if (!token) {
    return (
      <Status
        title="Joining Error"
        message="A video call token was not supplied."
      />
    );
  } else if (!room) {
    return (
      <Status title="Joining Error" message="A room code was not supplied." />
    );
  } else if (call.status === CallStatus.Disconnected) {
    return (
      <div className={classes.joiningRoot}>
        <Typography variant="h2">Looking good!</Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Press the join lesson button when you&apos;re ready.
        </Typography>
        <LocalPreview previewTracks={previewTracks} />
        <Button
          disabled={!previewTracks || previewTracks.length === 0}
          size="large"
          variant="contained"
          onClick={joinRoom}>
          Join lesson
        </Button>
      </div>
    );
  } else if (call.status === CallStatus.Connecting) {
    return (
      <div className={classes.joiningRoot}>
        <Typography variant="h2" color="textSecondary">
          Joining call...
        </Typography>
      </div>
    );
  }

  // Check for booking in local storage
  let booking;
  if (bookingId) {
    // Try to retirve booking data from local storage
    const bookingStorage = localStorage.getItem(`call-${bookingId}`);

    // If we successfully retrieved an item from localStorage, parse it
    if (bookingStorage) {
      booking = JSON.parse(bookingStorage);
    }
  }
  window.addEventListener('resize', () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  // Render either the materials view, or the normal view

  const rowReverse: CSSProperties =
    width >= 500 && width <= 900 ? { flexDirection: 'row-reverse' } : {};

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div
          className={
            width > 900
              ? classes.materialsContainer
              : classes.materialsContainerResponsive
          }>
          <MaterialsPanel path={resource} info={booking?.info} />
        </div>
        {width > 900 && (
          <div className={classes.sidebar}>
            <Alert className={classes.tip} severity="info">
              <b>TIP:</b> If your internet connection is slow, turn off your
              video or ask your tutor to turn off their video to keep audio.
            </Alert>
            <Portlet height="auto">
              <BookingInfo
                info={booking?.info || booking?.bookings[0]?.info}
                groupSession={booking?.type === 'group'}
                booking={booking}
                onResource={(path: string) => {
                  console.log('resource', path);
                  setResource(path);
                }}
                hideOpenButton
              />
            </Portlet>
            <Countdown
              when={booking?.when}
              onFinished={() => {
                leaveCall();
                window.close();
              }}
            />
            <Divider className={classes.divider} />
            {call.screenTrack && (
              <ScreenShare screenShareTrack={call.screenTrack} />
            )}
            {remoteScreenShareTrack && (
              <ScreenShare screenShareTrack={remoteScreenShareTrack} />
            )}
            {call.connected && call.localParticipant && (
              <div className="participant">
                <Participant
                  isLocal
                  key={call.localParticipant.sid}
                  participant={call.localParticipant}
                  muted={true}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            )}
            {call.participants.map((participant) => (
              <div
                className="participant"
                style={{ marginLeft: width < 900 ? 20 : 0 }}
                key={participant.sid}>
                <Participant
                  participant={participant}
                  muted={false}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {width < 500 && (
        <div className={classes.bottombar}>
          <Countdown
            when={booking?.when}
            onFinished={() => {
              leaveCall();
              window.close();
            }}
          />
          <div
            className={classes.participants}
            style={{ flexDirection: 'row-reverse' }}>
            {call.connected && call.localParticipant && (
              <div
                className="participant"
                style={{
                  marginRight: 'auto'
                }}>
                <Participant
                  isLocal
                  key={call.localParticipant.sid}
                  participant={call.localParticipant}
                  muted={true}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            )}
            {call.participants.map((participant) => (
              <div className="participant" key={participant.sid}>
                <Participant
                  participant={participant}
                  muted={false}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            ))}

            {call.screenTrack && (
              <ScreenShare screenShareTrack={call.screenTrack} />
            )}
            {remoteScreenShareTrack && (
              <ScreenShare screenShareTrack={remoteScreenShareTrack} />
            )}
          </div>
        </div>
      )}
      {width > 500 && width < 900 ? (
        <div className={classes.landscapView}>
          <div className={classes.controls}>
            <Countdown
              when={booking?.when}
              onFinished={() => {
                leaveCall();
                window.close();
              }}
            />
            <Controls
              bookingId={bookingId}
              onDisconnect={() => window.close()}
            />
          </div>
          <div className={classes.bottombarLandscape} style={rowReverse}>
            {call.connected && call.localParticipant && (
              <div className="participant">
                <Participant
                  isLocal
                  key={call.localParticipant.sid}
                  participant={call.localParticipant}
                  muted={true}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            )}

            {call.participants.map((participant) => (
              <div className="participant" key={participant.sid}>
                <Participant
                  participant={participant}
                  muted={false}
                  virtualBackgroundProcessor={virtualBackgroundProcessor}
                  setVirtualBackgroundProcessor={setVirtualBackgroundProcessor}
                  virtualBackgroundApplied={virtualBackgroundApplied}
                  setVirtualBackgroundApplied={setVirtualBackgroundApplied}
                />
              </div>
            ))}
            {call.screenTrack && (
              <ScreenShare screenShareTrack={call.screenTrack} />
            )}
            {remoteScreenShareTrack && (
              <ScreenShare screenShareTrack={remoteScreenShareTrack} />
            )}
          </div>
        </div>
      ) : (
        <Controls bookingId={bookingId} onDisconnect={() => window.close()} />
      )}
    </div>
  );
}

export default Call;
