import React, { useState, useEffect, useContext } from 'react';
import { Button, Divider, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { AuthContext } from 'services/auth';
import styles from './styles';

const useStyles = makeStyles(styles);

const GroupSessionBookingInfo = ({ bookings, hideOpenButton }) => {
  const auth = useContext(AuthContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [materialsData, setMaterialsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        // Step 1: Fetch materials for all bookings
        const materials = await auth.api.materials.getMaterials();

        // Step 2: Fetch courses for each booking's material
        const materialsWithCourses = await Promise.all(
          bookings.map(async (booking) => {
            const materialId = booking.info.topic.material;
            const material = materials.find((m) => m.id === materialId);

            // Fetch the courses for the given material
            const courses = await auth.api.materials.getCourses(materialId);

            // Find the course for the specific topic in the booking
            const course = courses.find(
              (c) => c.id === booking.info.topic.course
            );

            // Find the lesson for the course and the booking
            const lesson = course
              ? course.lessons.find((l) => l.id === booking.info.topic.lesson)
              : null;

            return {
              material,
              course,
              lesson,
              booking
            };
          })
        );

        // Step 3: Set the data to the state
        setMaterialsData(materialsWithCourses);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch materials or courses');
        setLoading(false);
        enqueueSnackbar('Error fetching materials or courses', {
          variant: 'error'
        });
      }
    };

    if (bookings && bookings.length) {
      fetchMaterials();
    }
  }, [bookings, auth, enqueueSnackbar]);

  const handleOpenMaterial = (lesson, booking) => {
    const lessonStart = booking.when - 20 * 60 * 1000;
    const lessonEnd = booking.when + 24 * 60 * 60 * 1000;

    if (Date.now() >= lessonStart && Date.now() <= lessonEnd) {
      auth.api.materials.openLessonDocument(enqueueSnackbar, lesson);
    } else {
      enqueueSnackbar(
        'You can open the material 20 minutes before to 24 hours after the lesson starts.',
        { variant: 'warning' }
      );
    }
  };

  const renderBookingDetails = (materialData) => {
    const { material, course, lesson, booking } = materialData;

    // If any of the key data is missing, return a placeholder
    if (!material || !course || !lesson) {
      return (
        <Typography color="textSecondary" variant="body2">
          Booking information is incomplete. Please try again later.
        </Typography>
      );
    }

    return (
      <div className={classes.details}>
        <div className={classes.detailsHeader}>
          <div className={classes.detailsHeaderImgWrapper}>
            {material.icon ? (
              <img
                className={classes.detailsHeaderImg}
                src={material.icon}
                alt={material.name}
              />
            ) : (
              <Skeleton variant="circle" width={40} height={40} />
            )}
          </div>
          <div className={classes.detailsHeaderInfo}>
            <Typography variant="h5">
              <b>{material.name}</b>
            </Typography>
            <Typography color="textSecondary">
              <b>{course?.name}</b>
            </Typography>
            <Typography color="textSecondary">
              {lesson?.code} {lesson?.name}
            </Typography>
            <Typography color="textSecondary">
              {lesson && `Level ${lesson.level}`}
            </Typography>
            {!hideOpenButton && (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                fullWidth
                className={classes.detailsHeaderButton}
                onClick={() => handleOpenMaterial(lesson, booking)}>
                Open Material
              </Button>
            )}
          </div>
        </div>
        <Divider className={classes.detailsHeaderDivider} />
        {/* <Typography color="textSecondary">Session Time: {time}</Typography>
        <Typography color="textSecondary">
          Total Lessons: {booking.lessonCount}
        </Typography> */}
      </div>
    );
  };

  if (loading) {
    return (
      <div>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="text" width="60%" />
      </div>
    );
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <div>
      {materialsData.map((materialData) => (
        <div key={materialData.booking.id}>
          {renderBookingDetails(materialData)}
        </div>
      ))}
    </div>
  );
};

export default GroupSessionBookingInfo;
