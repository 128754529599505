import { lazy } from 'react';
import { View } from '..';

import { importRetry as retry } from 'helpers';

// Icons
import {
  DashboardRounded as DashboardIcon,
  PeopleRounded as PeopleIcon,
  FolderOpenRounded as FolderIcon,
  LibraryBooksRounded as LibraryIcon,
  AnnouncementRounded as AnnouncementIcon,
  ReportRounded as ReportIcon,
  FormatListBulletedRounded as FormatListBulletedRoundedIcon
  // CalendarTodayRounded as CalendarTodayRoundedIcon
} from '@mui/icons-material';

// View components
const Dashboard = lazy(() => retry(() => import('views/admin/Dashboard')));
const Announcements = lazy(() =>
  retry(() => import('views/admin/Announcements'))
);
const Applications = lazy(() =>
  retry(() => import('views/admin/Applications'))
);
const Users = lazy(() => retry(() => import('views/admin/Users')));
const Reports = lazy(() => retry(() => import('views/admin/Reports')));
const Profile = lazy(() => retry(() => import('views/admin/Profile')));
const Materials = lazy(() => retry(() => import('views/admin/Materials')));
const MaterialInfo = lazy(() =>
  retry(() => import('views/admin/MaterialInfo'))
);
const UserGuidesAndVideos = lazy(() =>
  retry(() => import('views/admin/UserguidesVideos'))
);
const UserGuideManagement = lazy(() =>
  retry(() => import('views/admin/UserGuideManagement'))
);

// const GroupSessionSchedule = lazy(() =>
//   retry(() => import('views/admin/GroupSessions'))
// );

const views: View[] = [
  {
    route: '/admin/dashboard',
    text: 'Dashboard',
    icon: <DashboardIcon />,
    component: Dashboard
  },
  {
    route: '/admin/announcements',
    text: 'Announcements',
    icon: <AnnouncementIcon />,
    component: Announcements
  },
  {
    route: '/admin/applications',
    text: 'Applications',
    icon: <FolderIcon />,
    component: Applications
  },
  {
    route: '/admin/users',
    text: 'Users',
    icon: <PeopleIcon />,
    component: Users
  },
  {
    route: '/admin/users/:uid',
    component: Profile
  },
  {
    route: '/admin/reports',
    text: 'Reports',
    icon: <ReportIcon />,
    component: Reports
  },
  {
    route: '/admin/materials',
    text: 'Materials',
    icon: <LibraryIcon />,
    component: Materials
  },
  {
    route: '/admin/materials/:material',
    component: MaterialInfo
  },
  // {
  //   route: '/admin/schedule',
  //   text: 'Schedule',
  //   icon: <CalendarTodayRoundedIcon />,
  //   component: GroupSessionSchedule
  // },
  {
    route: '/admin/userguides&videos',
    text: 'User Guides & Videos',
    icon: <FormatListBulletedRoundedIcon />,
    component: UserGuidesAndVideos
  },
  {
    route: '/admin/userguides&videos/:category',
    component: UserGuideManagement
  }
];

export default views;
