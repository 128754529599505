import { Fragment, PureComponent, createRef } from 'react';

// Authentication provider
import { withAuth } from 'services/auth';

// Authentication provider
import { withRouter } from 'react-router-dom';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Material UI components / helpers
import { Paper, Grid, Button, Divider, withStyles } from '@material-ui/core';

// Mateirla UI Lab
import { Alert } from '@material-ui/lab';

// Luxon date helpers
import { DateTime } from 'luxon';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Export cleanup
import compose from 'recompose/compose';

// Async status
import {
  AsyncStatus,
  BookingCard,
  DateRangePicker,
  TabContainer,
  GroupSessionBookingHistoryCard
} from 'components';
// Submit feedback dialog
import { SubmitFeedbackDialog } from '../..';

// Component styles
import styles from './styles';

class HistoryBookingsView extends PureComponent {
  constructor(props) {
    super(props);
    // Initialzie the state
    this.state = {
      bookings: null,
      error: null,
      groupSessions: null,
      groupSessionsError: null,
      groupSessionsLoading: false,
      bookingsLoading: false
    };
    // Create a React ref for the cancel confirm dialog
    this.cancelConfirmDialog = createRef();
    this.submitFeedbackDialog = createRef();
  }

  componentDidMount() {
    // Initial data fetch
    this.fetchBookings();
  }

  fetchBookings = async (
    dateRange = null,
    isGroupSession = false,
    store = true
  ) => {
    try {
      const { auth } = this.props;
      const [startDate, endDate] = dateRange || [
        DateTime.local().minus({ months: 1 }),
        DateTime.local()
      ];

      if (isGroupSession) {
        this.setState({ groupSessionsLoading: true });
        const groupSessionsData =
          await auth.api.groupSessions.getGroupSessionsHistory(
            startDate.startOf('day').toMillis(),
            endDate.endOf('day').toMillis(),
            store
          );
        this.setState({
          groupSessions: groupSessionsData,
          groupSessionsError: null,
          groupSessionsLoading: false
        });
      } else {
        this.setState({ bookingsLoading: true });
        const data = await auth.api.bookings.getMyBookingHistory(
          startDate.startOf('day').toMillis(),
          endDate.endOf('day').toMillis(),
          store
        );
        // Update the component's state with the bookings.
        this.setState({
          bookings: data.bookings.filter(
            (booking) =>
              !['pending', 'in-progress', 'booked'].includes(booking.status)
          ),
          error: null,
          bookingsLoading: false
        });
      }
    } catch (error) {
      // Update the component's state to show an error
      this.setState({
        error: isGroupSession ? null : getErrorMessage(error),
        groupSessionsError: isGroupSession ? getErrorMessage(error) : null,
        groupSessionsLoading: false,
        bookingsLoading: false
      });
      // Log the error to the console.
      console.error(error);
    }
  };

  onSubmitFeedback = async (booking, values) => {
    // Retrive the enqueueSnackbar function and auth provider from props
    const { auth, enqueueSnackbar } = this.props;
    try {
      enqueueSnackbar('Submitting feedback...');
      await auth.api.bookings.submitFeedback(booking.id, values);
      // Update the state
      this.setState({
        bookings: this.state.bookings.map((stateBooking) =>
          booking.id === stateBooking.id
            ? { ...stateBooking, status: 'completed', feedback: values }
            : stateBooking
        )
      });
      enqueueSnackbar('Feedback submitted!', { variant: 'success' });
    } catch (error) {
      // Log the error to the console.
      console.error(error);
      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  };

  retryAPICall = (isGroupSession = false) => {
    if (isGroupSession) {
      this.setState({ groupSessions: null, groupSessionsError: null });
      this.fetchBookings(null, true);
    } else {
      this.setState({ bookings: null, error: null });
      this.fetchBookings();
    }
  };

  gotoSchedule = () => {
    // Grab the history provider from the props
    const { auth, history, onUpdateTab } = this.props;
    // Redirect the user to the bookings/schedule page.
    if (auth.role === 'student') {
      onUpdateTab(0);
    } else {
      history.push('/tutor/schedule');
    }
  };

  onDateChanged = (dateRange, isGroupSession) => {
    if (isGroupSession) {
      this.setState({ groupSessions: null, groupSessionsError: null });
      this.fetchBookings(dateRange, true);
    } else {
      this.setState({ bookings: null, error: null });
      this.fetchBookings(dateRange);
    }
  };

  handleTabChange = (index) => {
    if (index === 0 && !this.state.bookings) {
      this.fetchBookings();
    } else if (index === 1 && !this.state.groupSessions) {
      this.fetchBookings(null, true);
    }
  };

  render() {
    const {
      bookings,
      error,
      groupSessionsError,
      groupSessions,
      bookingsLoading,
      groupSessionsLoading
    } = this.state;

    const { classes, auth, onViewFeedback } = this.props;

    const tabs = [
      {
        label: 'One-on-One Lessons',
        component: (
          <>
            {auth.role === 'tutor' && (
              <SubmitFeedbackDialog
                ref={this.submitFeedbackDialog}
                onConfirm={this.onSubmitFeedback}
              />
            )}
            <Alert
              className={classes.reminderAlert}
              severity="info"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => this.retryAPICall(false)}>
                  Refresh Bookings
                </Button>
              }>
              <b>Reminder: </b>
              {auth.role === 'tutor'
                ? 'Click on student profiles to view feedback history'
                : 'Click on teacher photos to navigate to their profile'}
            </Alert>
            <Paper className={classes.dateControls}>
              <DateRangePicker
                initialValues={[
                  DateTime.local().minus({ months: 1 }),
                  DateTime.local()
                ]}
                onChange={(dateRange) => this.onDateChanged(dateRange, false)}
              />
            </Paper>
            {bookingsLoading ? (
              <AsyncStatus loading />
            ) : error ? (
              <AsyncStatus
                error={error}
                onRetry={() => this.retryAPICall(false)}
                retry
              />
            ) : bookings && bookings.length === 0 ? (
              <AsyncStatus
                error="No bookings found for time period"
                onRetry={this.gotoSchedule}
                retryText={`go to ${
                  auth.role === 'student' ? 'bookings search' : 'schedule'
                }`}
                retrySize="medium"
                textVariant="h4"
                retry
              />
            ) : (
              <Grid container spacing={2}>
                {bookings?.map((booking, index) => (
                  <Fragment key={booking.id}>
                    <Grid item xs={12}>
                      <BookingCard
                        auth={auth}
                        booking={booking}
                        history
                        onSubmitFeedback={() => {
                          if (auth.role === 'tutor')
                            this.submitFeedbackDialog.current.open(booking);
                        }}
                        onViewFeedback={(bookingFeedback) => {
                          onViewFeedback(bookingFeedback);
                        }}
                      />
                    </Grid>
                    {index !== bookings.length - 1 && (
                      <Grid key={booking.id} item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            )}
          </>
        )
      }
      // {
      //   label: 'Group Sessions',
      //   component: (
      //     <>
      //       <Paper className={classes.dateControls}>
      //         <DateRangePicker
      //           initialValues={[
      //             DateTime.local().minus({ months: 1 }),
      //             DateTime.local()
      //           ]}
      //           onChange={(dateRange) => this.onDateChanged(dateRange, true)}
      //         />
      //       </Paper>
      //       {groupSessionsLoading ? (
      //         <AsyncStatus loading />
      //       ) : groupSessionsError ? (
      //         <AsyncStatus
      //           error={groupSessionsError}
      //           onRetry={() => this.retryAPICall(true)}
      //           retry
      //         />
      //       ) : groupSessions === null || groupSessions?.length === 0 ? (
      //         <AsyncStatus
      //           error="No group sessions found for time period"
      //           onRetry={this.gotoSchedule}
      //           retryText={`go to ${
      //             auth.role === 'student' ? 'group sessions search' : 'schedule'
      //           }`}
      //           retrySize="medium"
      //           textVariant="h4"
      //           retry
      //         />
      //       ) : (
      //         <Grid container spacing={2}>
      //           {groupSessions?.map((session, index) => (
      //             <Fragment key={session.id}>
      //               <Grid item xs={12}>
      //                 <GroupSessionBookingHistoryCard
      //                   groupSession={session}
      //                   auth={auth}
      //                 />
      //               </Grid>
      //               {index !== groupSessions.length - 1 && (
      //                 <Grid item xs={12}>
      //                   <Divider />
      //                 </Grid>
      //               )}
      //             </Fragment>
      //           ))}
      //         </Grid>
      //       )}
      //     </>
      //   )
      // }
    ];

    // Return a rendered list of the items.
    return (
      <TabContainer
        tabs={tabs}
        initial={0}
        view={false}
        onChange={this.handleTabChange}
      />
    );
  }
}

export default compose(
  withSnackbar,
  withRouter,
  withAuth,
  withStyles(styles)
)(HistoryBookingsView);
