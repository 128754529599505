import React, { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Tooltip,
  Button,
  Popover,
  makeStyles
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { AssignmentRounded as LessonIcon } from '@mui/icons-material';

import { BookingInfo } from 'components';
import { getErrorMessage, getInitials } from 'helpers';

// Style
import styles from './styles';
import { AuthContext } from 'services/auth';

const useStyles = makeStyles(styles);

function GroupSessionBookingCard({ groupSession }) {
  const classes = useStyles();
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [data, setData] = useState({}); // Store data for each booking
  const [loading, setLoading] = useState({}); // Loading state per booking
  const [error, setError] = useState({}); // Error state per booking
  const auth = useContext(AuthContext);

  const {
    profile: {
      tutor: { name, image }
    },
    tutor,
    when,
    bookings,
    maxStudentCount,
    minStudentCount
  } = groupSession;

  const studentCount = bookings.reduce(
    (count, booking) =>
      count + (booking.studentIds ? booking.studentIds.length : 0),
    0
  );

  const sessionDate = DateTime.fromMillis(when || 0).toFormat('d LLL h:mm a');
  const relative = DateTime.fromMillis(when || 0).toRelative();

  const fetchData = async (info, bookingId) => {
    try {
      const [materialData, courseData] = await Promise.all([
        auth.api.materials.getMaterials(),
        auth.api.materials.getCourses(info.topic.material)
      ]);

      const material = materialData.find(
        (entry) => entry.id === info.topic.material
      );
      const course = courseData.find((entry) => entry.id === info.topic.course);
      const lesson = course?.lessons.find(
        (lesson) => lesson.id === info.topic.lesson
      );

      // Return the data as a simple object with keys
      return {
        material,
        course,
        lesson
      };
    } catch (error) {
      console.error(error);
      return { error: 'Failed to fetch data' };
    }
  };

  useEffect(() => {
    // Iterate through each booking and fetch its data
    bookings?.forEach(async (booking) => {
      const { bookingId, info } = booking;
      const bookingData = await fetchData(info, bookingId);
      setData((prevData) => ({
        ...prevData,
        [bookingId]: bookingData
      }));
    });
  }, [bookings, auth]);

  return (
    <>
      <Card style={{ marginBottom: '16px' }}>
        <CardContent>
          {bookings?.map((booking) => {
            console.log('booking', booking);
            const { bookingId, info } = booking;
            const bookingData = data[bookingId] || {};
            const isPte = booking?.tutor?.isPte;
            console.log(isPte);

            return (
              <Grid container key={booking.bookingId} spacing={4}>
                <Grid item xs={12} sm="auto">
                  <>
                    <Tooltip title="Go to Profile" aria-label="Go to profile">
                      <Link to={`/student/profile/${tutor}`}>
                        <Avatar
                          src={image}
                          alt={name}
                          className={classes.avatar}>
                          {!image && (
                            <Typography variant="h5" style={{ color: 'white' }}>
                              {getInitials(name || '')}
                            </Typography>
                          )}
                        </Avatar>
                      </Link>
                    </Tooltip>
                    <Typography
                      variant="h6"
                      style={{ textAlign: 'center', marginTop: '8px' }}>
                      {isPte ? 'PTE WorkPlace' : 'WorkPlace'}
                    </Typography>
                  </>
                </Grid>
                <Grid item xs={12} sm>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <Grid item>
                      <Typography
                        variant="h3"
                        style={{
                          marginBottom: '10px'
                        }}>
                        {bookingData?.course?.name}
                        &nbsp;
                        {bookingData?.lesson?.level}
                      </Typography>
                      <Typography variant="h3">{name}</Typography>
                      <div style={{ marginTop: '10px' }}>
                        {/* Add space between name and status/date */}
                        <Grid container spacing={1} alignItems="center">
                          <>
                            <Grid container alignItems="center" spacing={1}>
                              <Grid item>
                                <Chip
                                  size="small"
                                  label={booking.status}
                                  color="primary"
                                />
                                {auth.role == 'tutor' && (
                                  <Chip
                                    size="small"
                                    label={`${
                                      booking?.students?.length ?? 0
                                    } students`}
                                    color="success"
                                  />
                                )}
                              </Grid>
                              <Grid item>
                                <Typography color="textSecondary">
                                  {`${sessionDate}, ${relative}`}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Popover
                              id={
                                popoverAnchor
                                  ? 'booking-info-popover'
                                  : undefined
                              }
                              open={Boolean(popoverAnchor)}
                              anchorEl={popoverAnchor}
                              onClose={() => setPopoverAnchor(null)}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}>
                              <BookingInfo
                                groupSession={true}
                                info={booking.info}
                                booking={booking}
                              />
                            </Popover>
                          </>
                        </Grid>
                      </div>
                    </Grid>
                    {/* <Grid item>
                  <Chip
                    size="small"
                    label={`${studentCount}/${maxStudentCount} Students`}
                    color={
                      studentCount >= minStudentCount ? 'primary' : 'default'
                    }
                  />
                </Grid> */}
                  </Grid>
                  <div
                    style={{
                      marginTop: '20px'
                    }}>
                    <Button
                      size="small"
                      className={classes.gutterRight}
                      variant="contained"
                      onClick={(event) => setPopoverAnchor(event.currentTarget)}
                      startIcon={<LessonIcon />}>
                      View Details
                    </Button>
                  </div>
                </Grid>
              </Grid>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
}

export default GroupSessionBookingCard;
