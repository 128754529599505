import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Chip,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

import { getInitials } from 'helpers';

// Style
import styles from './styles';
import { AuthContext } from 'services/auth';

const useStyles = makeStyles(styles);

function GroupSessionBookingHistoryCard({ groupSession }) {
  const classes = useStyles();
  const { bookings, maxStudentCount, minStudentCount } = groupSession;
  const [data, setData] = useState({});
  const auth = useContext(AuthContext);

  const fetchData = async (info, bookingId) => {
    try {
      const [materialData, courseData] = await Promise.all([
        auth.api.materials.getMaterials(),
        auth.api.materials.getCourses(info.topic.material)
      ]);

      const material = materialData.find(
        (entry) => entry.id === info.topic.material
      );
      const course = courseData.find((entry) => entry.id === info.topic.course);
      const lesson = course?.lessons.find(
        (lesson) => lesson.id === info.topic.lesson
      );

      // Return the data as a simple object with keys
      return {
        material,
        course,
        lesson
      };
    } catch (error) {
      console.error(error);
      return { error: 'Failed to fetch data' };
    }
  };

  useEffect(() => {
    // Iterate through each booking and fetch its data
    bookings?.forEach(async (booking) => {
      const { bookingId, info } = booking;
      const bookingData = await fetchData(info, bookingId);
      setData((prevData) => ({
        ...prevData,
        [bookingId]: bookingData
      }));
    });
  }, [bookings, auth]);

  return (
    <Fragment>
      {bookings?.map((booking) => {
        const {
          bookingId,
          status,
          info,
          tutor,
          when,
          studentIds = []
        } = booking;

        // Calculate the date and relative time
        const sessionDate = DateTime.fromMillis(when || 0).toFormat(
          'd LLL h:mm a'
        );
        const relativeDate = DateTime.fromMillis(when || 0).toRelative();

        // Calculate the student count for this specific booking
        const studentCount = studentIds.length;

        const bookingData = data[bookingId] || {};

        return (
          <Card key={bookingId} style={{ marginBottom: '16px' }}>
            <CardContent>
              <Grid container spacing={4}>
                {/* Tutor Avatar and Link */}
                <Grid
                  item
                  className={classes.avatarWrapper}
                  xs={12}
                  sm="auto"
                  md="auto"
                  lg="auto"
                  xl="auto">
                  <Tooltip title="Goto Profile" aria-label="Go to profile">
                    <Link to={`/student/profile/${tutor.uid}`}>
                      <Avatar
                        src={tutor.image}
                        alt={tutor.name}
                        className={classes.avatar}>
                        <Typography variant="h3" style={{ color: 'white' }}>
                          {getInitials(tutor.name || '')}
                        </Typography>
                      </Avatar>
                    </Link>
                  </Tooltip>
                </Grid>

                {/* Booking Information */}
                <Grid item xs={12} sm>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start">
                    {/* Name and Booking Status */}
                    <Grid item>
                      <Typography
                        variant="h3"
                        style={{
                          marginBottom: '10px'
                        }}>
                        {bookingData?.course?.name}
                        &nbsp;
                        {bookingData?.lesson?.level}
                      </Typography>
                      <Typography variant="h3">{tutor.name}</Typography>
                      <div style={{ marginTop: '10px' }}>
                        {/* Status and Date/Time Information */}
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Chip size="small" label={status} color="primary" />
                          </Grid>
                          <Grid item>
                            <Typography color="textSecondary">
                              {`${sessionDate}, ${relativeDate}`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>

                    {/* Student Count Information */}
                    {/* <Grid item>
                      <Chip
                        size="small"
                        label={`${studentCount}/${maxStudentCount} Students`}
                        color={
                          studentCount >= minStudentCount
                            ? 'primary'
                            : 'default'
                        }
                      />
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Fragment>
  );
}

export default GroupSessionBookingHistoryCard;
