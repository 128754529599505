import React, { PureComponent, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

// 'Under Development' and 404 views
import UnderDevelopment from 'views/shared/UnderDevelopment';
import NotFound from 'views/shared/NotFound';
import Call from 'views/shared/Call';
import SpeedTest from 'views/tutor/SpeedTest';

// Snackbar notifications
import { withSnackbar } from 'notistack';

// Function that retrieves the view components
import getViewComponents from 'services/views';
import firebase from 'firebase/app';

// Dashboard layout & join card
import { Dashboard as DashboardLayout } from 'layouts';
import { JoinCard, PageLoader } from 'components';

class AuthRoutes extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the routes array and set the 'first' flag to true.
    this.routes = [];
    this.first = true;

    // Initialize the component's state
    this.state = {
      speedtestOutcome: false
    };

    this.isLessonTokensChanged = React.createRef(null);
    this.isGroupLessonTokenChanged = React.createRef(null);
    this.isPTELessonTokenChanged = React.createRef(null);
    this.isPTEGroupTokenChanged = React.createRef(null);

    // Bind all required functions
    this.speedtestRoute = this.speedtestRoute.bind(this);
    this.speedtestSuccess = this.speedtestSuccess.bind(this);

    // Define the toFirstUpper function
    this.toFirstUpper = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };
  }

  validRoute() {
    // Check whether this is our first redirect
    if (this.first) {
      // Update the 'first' flag
      this.first = false;

      // If the route is included within the acceptable routes, return in.
      const { initial, auth } = this.props;

      // Redirect to call route
      if (initial.pathname === '/call') return '/call' + initial.search;

      // If the acceptable routes includes base, else redirect to dashboard
      if (
        this.routes.find((route) => initial.pathname.startsWith(route)) &&
        !initial.pathname.includes(':')
      ) {
        return initial.pathname + initial.search;
      }

      // Redirect the user to the dashboard
      return `/${auth.role}/dashboard`;
    }

    // Return the not-found route
    return '/not-found';
  }

  speedtestRoute() {
    // Grab the authentication provider from the props
    const { auth } = this.props;

    // Return the speedtest route
    return <SpeedTest auth={auth} onSuccess={this.speedtestSuccess} />;
  }

  speedtestSuccess() {
    // Update the state to render the dashboard
    this.setState({
      speedtestOutcome: true
    });
  }

  componentDidMount() {
    let user = firebase.auth().currentUser;
    const { enqueueSnackbar } = this.props;
    if (user) {
      const docRef = firebase.firestore().collection('students').doc(user?.uid);
      docRef.onSnapshot((snapshot) => {
        const currentLessonTokens = snapshot?.data()?.data?.lessonTokens;
        const currentPTELessonTokens = snapshot?.data()?.data?.PTELessonTokens;
        const currentGroupLesson = snapshot?.data()?.data?.groupLessonTokens;
        const currentPTEGroup = snapshot?.data()?.data?.PTEGroupLessonTokens;
        if (
          this.isLessonTokensChanged.current !== currentLessonTokens &&
          typeof currentLessonTokens != 'undefined'
        ) {
          if (this.isLessonTokensChanged.current !== null) {
            const totalTokens = Math.abs(
              this.isLessonTokensChanged.current - currentLessonTokens
            );

            if (this.isLessonTokensChanged.current < currentLessonTokens) {
              enqueueSnackbar(
                `${totalTokens} Lesson Tokens have been added in your account`
              );
            } else {
              enqueueSnackbar(
                `${totalTokens} Lesson Tokens have been removed from your account`
              );
            }
          }
          this.isLessonTokensChanged.current = currentLessonTokens;
        }
        if (
          this.isGroupLessonTokenChanged.current !== currentGroupLesson &&
          typeof currentGroupLesson != 'undefined'
        ) {
          if (this.isGroupLessonTokenChanged.current !== null) {
            const totalTokens = Math.abs(
              this.isGroupLessonTokenChanged.current - currentGroupLesson
            );

            if (this.isGroupLessonTokenChanged.current < currentGroupLesson) {
              enqueueSnackbar(
                `${totalTokens} Group Lesson Tokens have been added in your account`
              );
            } else {
              enqueueSnackbar(
                `${totalTokens} Group Lesson Tokens have been removed from your account`
              );
            }
          }
          this.isGroupLessonTokenChanged.current = currentGroupLesson;
        }
        if (
          this.isPTELessonTokenChanged.current !== currentPTELessonTokens &&
          typeof currentPTELessonTokens != 'undefined'
        ) {
          if (this.isPTELessonTokenChanged.current !== null) {
            const totalTokens = Math.abs(
              this.isPTELessonTokenChanged.current - currentPTELessonTokens
            );
            if (this.isPTELessonTokenChanged.current < currentPTELessonTokens) {
              enqueueSnackbar(
                `${totalTokens} PTE Lesson Tokens have been added in your account`
              );
            } else {
              enqueueSnackbar(
                `${totalTokens} PTE Lesson Tokens have been removed from your account`
              );
            }
          }
          this.isPTELessonTokenChanged.current = currentPTELessonTokens;
        }
        if (
          this.isPTEGroupTokenChanged.current !== currentPTEGroup &&
          typeof currentPTEGroup != 'undefined'
        ) {
          if (this.isPTEGroupTokenChanged.current !== null) {
            const totalTokens = Math.abs(
              this.isPTEGroupTokenChanged.current - currentPTEGroup
            );
            if (this.isPTEGroupTokenChanged.current < currentPTEGroup) {
              enqueueSnackbar(
                `${totalTokens} PTE Group Lesson Tokens have been added in your account`
              );
            } else {
              enqueueSnackbar(
                `${totalTokens} PTE Group Lesson Tokens have been removed from your account`
              );
            }
          }
          this.isPTEGroupTokenChanged.current = currentPTEGroup;
        }
      });
    }
  }

  render() {
    // Grab the auth and location providers from our properties
    const { auth, initial, currentTheme, toggleDarkTheme } = this.props;
    let { location } = this.props;
    const { speedtestOutcome } = this.state;

    if (location.pathname === '/call') {
      return <Call />;
    }

    // Check if we're a tutor, and hence, need to complete a speed test
    if (auth.role === 'tutor' && speedtestOutcome === false) {
      return (
        <Switch>
          <Route component={this.speedtestRoute} exact path="/speed-test" />
          <Redirect to="/speed-test" />
        </Switch>
      );
    }

    // Return the authenticated view.
    return (
      <DashboardLayout
        title={`${this.toFirstUpper(auth.role)} Portal`}
        showBackButton={
          location.state && location.pathname.split('/').length > 3
        }
        initial={initial.pathname}
        currentTheme={currentTheme}
        toggleDarkTheme={toggleDarkTheme}>
        {['student', 'tutor'].includes(auth.role) && <JoinCard auth={auth} />}
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {getViewComponents(auth.role, auth.isDevBuild).map((item) => {
              // Push the routes into our 'routes' property so we can use them
              // for validation within the this.validRoute() method
              this.routes.push(item.route);

              // Return the route.
              return (
                <Route
                  component={item.component}
                  path={item.route}
                  key={item.route}
                  exact
                />
              );
            })}
            <Route
              component={UnderDevelopment}
              exact
              path="/under-development"
            />
            <Route component={NotFound} exact path="/not-found" />
            <Redirect to={this.validRoute()} />
          </Switch>
        </Suspense>
      </DashboardLayout>
    );
  }
}

export default withSnackbar(withRouter(AuthRoutes));
